html, body {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  align-items: center;
}
.flexc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
